import { useEffect, useState } from "react";

export const useKeyCheck = ({ key }) => {
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (key === event.keyCode) {
        setIsPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (key === event.keyCode) {
        setIsPressed(false);
      }
    };

    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return isPressed;
};
