import React, { useEffect, useState } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import {
  headerStyle,
  logoStyle,
  logoContainerStyle,
  buttonContainerStyle,
} from "./styles/general";
import PageTitle from "./components/pageTitle";
import {
  NotificationsApi,
  CookieRequestComponent,
  TenantApi,
  StorageManager,
} from "@unity/components";
import { Cottage } from "@mui/icons-material";
import AccountButton from "./components/account/accountButton";
import CommunicationButton from "./components/communication/communicationButton";
import NotificationButton from "./components/notification/notificationButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppTour from "./components/tour";
import { TourButton } from "./components/tour/tourButton";

const breakpoint = 990;
const TENANTTYPESETTINGS = "tenant";
const DEFAULTSETTINGS = {
  portalUrl: window.location + "/portalURLNotFound",
};

export default function Header({ context, channels }) {
  const [pageRefresh, setPageRefresh] = useState(false);
  const [settings, setSettings] = useState(DEFAULTSETTINGS);

  const sm = new StorageManager();

  const fetchNotifications = async () => {
    try {
      const res = await NotificationsApi.getNotifications();
      if (!res.data) throw "Call Failed";

      window.dispatchEvent(
        new CustomEvent("NotificationsFetched", { detail: res.data.length })
      );
    } catch (e) {
      console.error("failed to load notifications", e);
      alert("Failed to load notifications. Please refresh and try again");
    }
  };

  const loadSettings = async () => {
    const localSettings = sm.getObject("tenantSettings", null);
    if (localSettings) return setSettings(localSettings);

    try {
      const res = await TenantApi.moduleSettingsIndexForCategory({
        category_type: TENANTTYPESETTINGS,
      });
      if (!res.ok) throw "Call Failed";
      if (!Array.isArray(res?.data.data) || res.data.data.length <= 0)
        return setSettings(DEFAULTSETTINGS);

      const setting = res.data.data[0];
      if (!setting.settings) return setSettings(DEFAULTSETTINGS);

      setSettings(setting.settings);
      sm.setObject("tenantSettings", setting.settings);
    } catch (e) {
      console.error("Failed to fetch settings", e);
      alert("Failed to load the settings. Please refresh and try again");
    }
  };

  const loadData = async () => {
    Promise.all([await fetchNotifications(), await loadSettings()])
      .then(() => {})
      .catch(() => {
        alert("Failed to load data for header");
      });
  };

  useEffect(() => {
    loadData();
    window.addEventListener(
      "resize",
      function (event) {
        if (pageRefresh) clearTimeout(pageRefresh);
        setPageRefresh(
          setTimeout(() => {
            loadData();
            setPageRefresh(false);
          }, 2000)
        );
      },
      true
    );
  }, []);

  return (
    <>
      <div style={headerStyle(context.theme)}>
        <div>
          <div
            onTouchStart={() => {
              window.dispatchEvent(new CustomEvent("triggerDrawerOpen"));
            }}
            style={logoContainerStyle(context.theme)}
          >
            {window.innerWidth > breakpoint ? (
              <img
                style={logoStyle()}
                src={context.logo}
                alt="Company Logo"
                width="170px"
                height="45px"
              />
            ) : (
              <MenuIcon
                style={{ color: "white" }}
                onClick={() => {
                  window.dispatchEvent(new CustomEvent("triggerDrawerOpen"));
                }}
              />
            )}
          </div>
        </div>
        <div>
          <PageTitle context={context} />
        </div>
        <div style={buttonContainerStyle(context.theme)}>
          <TourButton />
          <AccountButton context={context} />
          <CommunicationButton context={context} />
          <NotificationButton context={context} channels={channels} />
          <IconButton
            style={{ color: context.theme.top_menu.font }}
            onClick={() => {
              window.location = settings.portalUrl;
            }}
          >
            <Tooltip
              title={<Typography variant="body1">Return to Portal</Typography>}
              arrow
              placement="bottom"
            >
              <Cottage />
            </Tooltip>
          </IconButton>
        </div>
        <CookieRequestComponent />
      </div>
      <AppTour />
    </>
  );
}
