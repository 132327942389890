import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useTour } from "../../services/TourContext";
import { TourPopover } from "./tourPopover";
import { TourButtonComponent } from "@unity/components";

export const TourButton = () => {
  const { setTour } = useTour();
  const history = useHistory();

  const [anchor, setAnchor] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleClick = (module) => {
    setLoading(module);
    setTour({ open: false, module: null });
    history.push(`/${module}/index`);
    setTimeout(() => {
      setLoading(null);
      setAnchor(null);
      setTour({ open: true, module });
      window.dispatchEvent(new CustomEvent("tour", { detail: { open: true } }));
    }, 5000);
  };

  return (
    <>
      <TourButtonComponent onClick={(e) => setAnchor(e.currentTarget)} />
      <TourPopover
        anchor={anchor}
        setAnchor={setAnchor}
        onItemClick={handleClick}
        loading={loading}
      />
    </>
  );
};
