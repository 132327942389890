const headerStyle = (theme) => ({
  width: "100%",
  height: "100%",
  margin: "0px",
  overflow: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "5px",
  padding: "5px",
  backgroundColor: theme.top_menu.background,
  color: theme.top_menu.font,
});

const logoStyle = () => ({
  backgroundColor: "white",
  borderRadius: "5px",
});

const logoContainerStyle = (theme) => ({
  borderRightColor: theme.top_menu.font,
  borderRightWidth: "0.1px",
  borderRightStyle: "solid",
  paddingRight: "1vw",
});

const titleContainerStyle = (theme) => ({
  borderRightColor: theme.top_menu.font,
  borderRightWidth: "0.1px",
  borderRightStyle: "solid",
  paddingRight: "1vw",
});

const dividerStyle = (theme) => ({
  borderRightColor: theme.top_menu.font,
  borderRightWidth: "0.1px",
  borderRightStyle: "solid",
  height: "inherit",
});

const buttonContainerStyle = (theme) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",

  borderLeftColor: theme.top_menu.font,
  borderLeftWidth: "0.1px",
  borderLeftStyle: "solid",
  paddingLeft: "1vw",
  paddingRight: "1vw",
});

const loadingSpinerStyle = (theme) => ({
  "& .MuiCircularProgress-svg": { color: theme.top_menu.font },
});

const pageTitleContainer = () => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1vw",
});

const popoverStyle = () => ({
  "& .MuiPaper-root": {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: "5px",
  },
});

const popoverContainerStyle = (theme) => ({
  width: window.innerWidth <= 600 ? "80vw" : "40vw",
  height: "auto",
  backgroundColor:
    theme.top_menu.openBackground !== undefined
      ? theme.top_menu.openBackground
      : "rgba(27, 0, 164, 0.5)",
  backdropFilter: "blur(10px)",
});

const popoverHeaderStyle = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "inherit",
  padding: "5px",
  backgroundColor: theme.top_menu.background,
  color: theme.top_menu.font,
  borderTopRightRadius: "5px",
  borderTopLeftRadius: "5px",
  margin: "0px 0px 5px px",
});

const popoverHeaderTitleContainerStyle = () => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  marginLeft: "0.5vw",
  gap: "0.5vw",
});

const popoverBodyStyle = (theme) => ({
  padding: "1vw",
  color: theme.top_menu.font,
});

const glassButtonStyle = (theme) => [
  {
    padding: "0.5vw",
    width: "80%",
    textAlign: "center",
    color: theme.top_menu.buttonFont ? theme.top_menu.buttonFont : "white",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
  },
  {
    "&.MuiButton-contained": {
      backgroundColor:
        theme.top_menu.buttonBackground !== undefined
          ? theme.top_menu.buttonBackground
          : "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(20px)",
    },
  },
  {
    "&.MuiButton-contained:hover": {
      backgroundColor:
        theme.top_menu.buttonBackground !== undefined
          ? theme.top_menu.buttonBackground
          : "rgba(0, 0, 0, 0.1)",
    },
  },
];

const centerAlignSubBoxStyle = () => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "1vw",
  margin: "0.5vw",
  width: "100%",
});

const twoBlockBox = () => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "0.5vw",
  alignItems: "center",
  textShadow: "0px 0px 5px grey",
  fontWeight: "bolder",
  padding: "0vw 1vw 0vw 1vw",
  width: "100%",
});

const broadcastFormStyle = () => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "1vh",
});

const broadcastFormElement = (theme) => ({
  color: theme.top_menu.font,
  width: "100%",
});

const selectStyle = (theme) => [
  {
    borderColor: theme.top_menu.font,
    color: theme.top_menu.font,
    height: "100%",
  },
  {
    "&.MuiOutlinedInput-root": {
      borderColor: theme.top_menu.font,
      color: theme.top_menu.font,
      height: "100%",
    },
  },
];

const textFieldStyle = (theme) => [
  {
    borderColor: theme.top_menu.font,
    color: theme.top_menu.font,
    height: "100%",
  },
  {
    "&.MuiTextField-root": {
      borderColor: theme.top_menu.font,
      color: theme.top_menu.font,
      height: "100%",
    },
  },
];

const notificationContainer = (theme) => ({
  overflowY: "scroll",
  height: "50vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "2vh",
  margin: "0.5vh",
  padding: "0.5vw",
});

const notificationBlockStyle = (theme) => ({
  width: "100%",
  padding: "1.5vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backdropFilter: "blur(10px)",
  borderRadius: "5px",
  borderTopRightRadius: "15px",
  borderTopLeftRadius: "15px",
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
});

const notificationBlockControls = (theme) => ({
  display: "flex",
  gap: "2vw",
});

const rightAlignedContained = () => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: "1vw",
  width: "100%",
  gap: "1vw",
});

const dialogHeaderStyle = (theme) => ({
  backgroundColor: theme.top_menu.background,
  color: theme.top_menu.font,
  textTransform: "capitalize",
});

const dialogTitleStyle = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const dialogBodyStyle = (theme) => [
  {
    backgroundColor:
      theme.top_menu.buttonBackground !== undefined
        ? theme.top_menu.buttonBackground
        : "rgba(0, 0, 0, 0.1)",
    color: theme.top_menu.font,
    backdropFilter: "blur(20px)",
  },
  {
    "& .MuiDialog-root": {
      backgroundColor:
        theme.top_menu.buttonBackground !== undefined
          ? theme.top_menu.buttonBackground
          : "rgba(0, 0, 0, 0.1)",
      color: theme.top_menu.font,
      backdropFilter: "blur(20px)",
    },
  },
  {
    "& .MuiDialog-paper": {
      backgroundColor:
        theme.top_menu.buttonBackground !== undefined
          ? theme.top_menu.buttonBackground
          : "rgba(0, 0, 0, 0.1)",
      color: theme.top_menu.font,
      backdropFilter: "blur(20px)",
    },
  },
  {
    "& .MuiDialog-container": {
      backgroundColor:
        theme.top_menu.buttonBackground !== undefined
          ? theme.top_menu.buttonBackground
          : "rgba(0, 0, 0, 0.1)",
      color: theme.top_menu.font,
      backdropFilter: "blur(20px)",
    },
  },
];

const dialogBodyContainerStyle = (theme) => ({
  padding: "1.5vw",
});

const accordionStyle = () => ({
  backgroundColor: "rgba(255,255,255,0.3)",
  backdropFilter: "blur(20px)",
  color: "white",
});

const headerTextField = (context) => [
  { color: "pink", borderColor: "pink" },
  {
    "& input": {
      color: context.theme.top_menu.font,
      border: `solid 0.5px rgba(0,0,0,0.0)`,
      borderRadius: "5px",
      padding: "1vw",
    },
  },
  { "& label": { color: context.theme.top_menu.font } },
];

const tourContainer = () => ({
  overflowY: "scroll",
  height: "50vh",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: "2vh",
  margin: "0.5vh",
  padding: "0.5vw",
  cursor: "pointer",
});

const tourItemStyle = () => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "1.5vw",
  backdropFilter: "blur(10px)",
  borderRadius: "5px",
  borderTopRightRadius: "15px",
  borderTopLeftRadius: "15px",
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
  "&:hover": {
    opacity: 0.7,
  },
});

export {
  headerStyle,
  headerTextField,
  logoStyle,
  logoContainerStyle,
  dividerStyle,
  buttonContainerStyle,
  titleContainerStyle,
  loadingSpinerStyle,
  pageTitleContainer,
  popoverStyle,
  popoverContainerStyle,
  popoverHeaderStyle,
  popoverHeaderTitleContainerStyle,
  popoverBodyStyle,
  glassButtonStyle,
  centerAlignSubBoxStyle,
  twoBlockBox,
  broadcastFormStyle,
  broadcastFormElement,
  selectStyle,
  textFieldStyle,
  notificationContainer,
  notificationBlockStyle,
  notificationBlockControls,
  rightAlignedContained,
  dialogTitleStyle,
  dialogHeaderStyle,
  dialogBodyStyle,
  dialogBodyContainerStyle,
  accordionStyle,
  tourContainer,
  tourItemStyle,
};
