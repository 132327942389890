import React from "react";
import {
  CircularProgress,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import {
  popoverStyle,
  popoverContainerStyle,
  popoverHeaderStyle,
  popoverHeaderTitleContainerStyle,
  popoverBodyStyle,
  tourContainer,
  tourItemStyle,
} from "../../styles/general";
import { Close, Tour } from "@mui/icons-material";

import { useAuth } from "../../services/AuthContext";
import { checkArray } from "../../utils/checkArray";

const transform = {
  vertical: "top",
  horizontal: "right",
};

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

export const TourPopover = ({ anchor, setAnchor, onItemClick, loading }) => {
  const { auth } = useAuth();

  const availableModules = Object.keys(auth.access);

  const open = Boolean(anchor);
  const id = open ? "tour-popover" : undefined;

  const handleClick = (item) => {
    onItemClick(item);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      onClose={() => {
        setAnchor(null);
      }}
      transformOrigin={transform}
      anchorOrigin={anchorOrigin}
      sx={popoverStyle()}
    >
      <div style={popoverContainerStyle(auth.theme)}>
        <div style={popoverHeaderStyle(auth.theme)}>
          <div style={popoverHeaderTitleContainerStyle()}>
            <Tour style={{ color: auth.theme.top_menu.font }} />
            <Typography variant="h5">Select a tour</Typography>
          </div>
          <IconButton
            style={{ color: auth.theme.top_menu.font, marginRight: "1vw" }}
            onClick={() => {
              setAnchor(null);
            }}
          >
            <Close />
          </IconButton>
        </div>
        <div style={popoverBodyStyle(auth.theme)}>
          {checkArray(availableModules) ? (
            <div
              style={tourContainer()}
              className="notifications-scroll-window"
            >
              {availableModules.map((el) => (
                <div onClick={() => handleClick(el)} style={tourItemStyle()}>
                  <Typography>{el}</Typography>
                  <CircularProgress
                    sx={{ visibility: loading === el ? "visible" : "hidden" }}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </Popover>
  );
};
