import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./header";

function Routes(props) {
  const context = props.context;
  return (
    <Router>
      <Route exact path="*">
        <Header context={context} channels={props.channels}></Header>
      </Route>
    </Router>
  );
}

export default Routes;
