import React, { useState, useEffect } from "react";
import { Notifications } from "@mui/icons-material";
import { Badge, IconButton, Tooltip, Typography } from "@mui/material";
import NotificationPopover from "./notificationPopover";
import { NotificationsApi } from "@unity/components";
import RedirectLogin from "../../common/redirectLogin";

export default function CommunicationButton({ context, channels }) {
  const [anchor, setAnchor] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = async () => {
    try {
      const res = await NotificationsApi.getNotifications();
      if (!res.data) throw "Call Failed";
      setNotifications(res.data);

      window.dispatchEvent(
        new CustomEvent("NotificationsFetched", { detail: res.data.length })
      );
    } catch (e) {
      console.error("failed to load notifications", e);
      // alert("Failed to load notifications. Please refresh and try again");
      return <RedirectLogin />;
    }
  };

  const loadData = async () => {
    fetchNotifications();
  };

  useEffect(() => {
    loadData();
    window.addEventListener("NotificationReceived", fetchNotifications);
  }, []);

  return (
    <>
      <IconButton
        style={{ color: context.theme.top_menu.font }}
        onClick={(e) => {
          setAnchor(e.currentTarget);
        }}
      >
        <Tooltip
          title={<Typography variant="body1">Notifications</Typography>}
          arrow
          placement="bottom"
        >
          <Badge badgeContent={notifications.length} color="error">
            <Notifications />
          </Badge>
        </Tooltip>
      </IconButton>
      <NotificationPopover
        context={context}
        anchor={anchor}
        setAnchor={setAnchor}
        channels={channels}
        notifications={notifications}
        fetchNotifications={fetchNotifications}
      />
    </>
  );
}
